
body {
    font-size: 0.8125rem !important;
}

body.wait *, body.wait {
    cursor: progress !important;
}

div.dataTables_wrapper div.dataTables_filter label {
    padding-left: 10px;
    width: 70%;
}

#h2_title,#h2_pf{
    display: none;
}

.dt-buttons{
    padding-top: 10px;
    padding-right: 10px;
}
@media only print {
    @page {
        size: auto;  
        margin: 0;  
    }

    body {
        padding-left: 1.3cm;
        padding-right: 1.3cm; 
        padding-top: 1.1cm;
    }

    input, textarea {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

    select {
        -webkit-appearance: button;
        -webkit-border-radius: 2px;
        -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        -webkit-padding-end: 20px;
        -webkit-padding-start: 2px;
        -webkit-user-select: none;
        background-image: url(../images/select-arrow.png), 
          -webkit-linear-gradient(#FAFAFA, #F4F4F4 40%, #E5E5E5);
        background-position: center right;
        background-repeat: no-repeat;
        border: 1px solid #AAA;
        color: #555;
        font-size: inherit;
        margin: 0;
        overflow: hidden;
        padding-top: 2px;
        padding-bottom: 2px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    #dv_pf_simulacao {
      width: auto;
      height: auto;
      overflow: visible;
    }

    .main-footer,
    #btn_simula,
    #btn_print {
        display: none;
    }

    #h2_title,
    #h2_pf {
        display: initial;
    }
 }
/*# sourceMappingURL=beekesh.css.map */